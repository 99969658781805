import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Collapse from "react-bootstrap/Collapse";

// Components
import SecondaryLanding from "../../../../layouts/secondary-landing";
import HeroChevron from "../../../../components/hero/hero-chevron";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import SEO from "../../../../components/seo/seo";
import getHeroImgVariables from "../../../../helpers/getHeroImgVariables";
import MktoForm from "../../../../components/mkto-form/mkto-form";
import Icon from "../../../../components/custom-widgets/icon";
import NotificationAlert from "../../../../components/notifications/notification-alert";
import Navigation from "../../../../components/navigation/navigation";

import InvestorRelationsNavData from "../../../../data/investor-relations/investor-relations-nav-data";

import showMktoForm from "../../../../helpers/showMktoForm";

import "../../../../components/accordion/accordion.bootstrap.scss";
import BestBanksDefault from "../../../../components/best-banks/best-banks-default";

const FinancialNews = () => {
  const title = "Financial Banking News";
  const description = "For the latest banking news and information, check WaFd Bank's news page.";
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/about-us/investor-relations/financial-news/hero-financial-news-02-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/about-us/investor-relations/financial-news/hero-financial-news-02-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/about-us/investor-relations/financial-news/hero-financial-news-02-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/about-us/investor-relations/financial-news/hero-financial-news-02-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/about-us/investor-relations/financial-news/hero-financial-news-02-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/about-us/investor-relations/financial-news/hero-financial-news-02-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/about-us/investor-relations/financial-news/hero-financial-news-02-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us/investor-relations/financial-news"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-financial-news-02-250.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "financial-news-hero",
    ...getHeroImgVariables(imgData),
    altText: "WaFd employees in Times Square NYC with WaFd Nasdaq sign",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Publicly Traded Since 1982 and Going Strong!"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn-1",
            containerClass: "btn-container-long",
            class: "btn-white btn-long",
            onClick: () => showMktoForm(1428),
            type: "button",
            text: "Subscribe to Financial News",
            icon: {
              position: "right",
              lib: "fas",
              name: "envelope",
              class: "float-right mt-1 ml-3"
            }
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      url: "/about-us/investor-relations",
      title: "Investor Relations"
    },
    {
      id: 3,
      active: true,
      title: "Financial News"
    }
  ];

  const financialNewsData = [
    {
      year: 2024,
      newsData: [
        {
          id: "financial-news-link-november-12-2024",
          dateId: "financial-news-date-november-12-2024",
          date: "November 12, 2024",
          filePath: "/2024/wafd-bank-dividend-release-20241112.pdf",
          text: "WaFd, Inc. Announces Cash Dividend of 26 cents per share"
        },
        {
          id: "financial-news-link-october-17-2024",
          dateId: "financial-news-date-october-17-2024",
          date: "October 17, 2024",
          filePath: "/2024/wafd-bank-earnings-release-20241017.pdf",
          text: "WaFd Inc. Announces Fourth Quarter and Fiscal 2024 Results (Fact Sheet follows on Page 14)"
        },
        {
          id: "financial-news-link-august-28-2024",
          dateId: "financial-news-date-august-28-2024",
          date: "August 28, 2024",
          filePath: "/2024/wafd-bank-investor-presentation-20240828.pdf",
          text: "Investor Presentation As of 6/30/2024"
        },
        {
          id: "financial-news-link-august-22-2024",
          dateId: "financial-news-date-august-22-2024",
          date: "August 22, 2024",
          filePath: "/2024/wafd-bank-press-release-20240822.pdf",
          text: "KBRA Initiates Ratings for WaFd Bank"
        },
        {
          id: "financial-news-link-august-13-2024",
          dateId: "financial-news-date-august-13-2024",
          date: "August 13, 2024",
          filePath: "/2024/wafd-bank-press-release-20240813.pdf",
          text: "WaFd, Inc. Announces Cash Dividend of 26 cents per share"
        },
        {
          id: "financial-news-link-july-16-2024",
          dateId: "financial-news-date-july-16-2024",
          date: "July 16, 2024",
          filePath: "/2024/wafd-bank-press-release-20240716.pdf",
          text: "WaFd Continues Tracking to Plan Post-Acquisition; Announces Quarterly Earnings Per Share of $0.75 (Fact Sheet follows on Page 17)"
        },
        {
          id: "financial-news-link-june-21-2024",
          dateId: "financial-news-date-june-21-2024",
          date: "June 21, 2024",
          filePath: "/2024/wafd-bank-press-release-20240621.pdf",
          text: "WaFd Bank Completes Sale of $2.8 Billion in Multi-Family Loans"
        },
        {
          id: "financial-news-link-may-14-2024",
          dateId: "financial-news-date-may-14-2024",
          date: "May 14, 2024",
          filePath: "/2024/wafd-bank-dividend-release-20240514.pdf",
          text: "WaFd, Inc. Announces Cash Dividend of 26 cents per share and Increase in Share Repurchase Authorization"
        },
        {
          id: "financial-news-link-may-09-2024",
          dateId: "financial-news-date-may-09-2024",
          date: "May 9, 2024",
          filePath: "/2024/wafd-bank-investor-presentation-20240508.pdf",
          text: "Investor Presentation As of 3/31/2024"
        },
        {
          id: "financial-news-link-april-22-2024",
          dateId: "financial-news-date-april-22-2024",
          date: "April 22, 2024",
          filePath: "/2024/wafd-bank-press-release-20240422.pdf",
          text: "WaFd Reports Second Quarter Fiscal 2024 Results Following Completion of Merger of Luther Burbank Corporation (Fact Sheet follows on Page 19)"
        },
        {
          id: "financial-news-link-february-15-2024",
          dateId: "financial-news-date-february-15-2024",
          date: "February 15, 2024",
          filePath: "/2024/wafd-bank-investor-presentation-20240215.pdf",
          text: "Investor Presentation As of 12/31/2023"
        },
        {
          id: "financial-news-link-february-13-2024",
          dateId: "financial-news-date-february-13-2024",
          date: "February 13, 2024",
          url: "https://www.virtualshareholdermeeting.com/WAFD2024",
          externalUrl: true,
          text: "WAFD, INC.'s Annual Meeting of Shareholders playback is now accessible at www.virtualshareholdermeeting.com/WAFD2024"
        },
        {
          id: "financial-news-link-february-13-2024-2",
          dateId: "financial-news-date-february-13-2024-2",
          date: "February 13, 2024",
          filePath: "/2024/wafd-bank-press-release-20240213.pdf",
          text: "WaFd, Inc. Announces Increase in its Cash Dividend to 26 cents per share"
        },
        {
          id: "financial-news-link-january-30-2024",
          dateId: "financial-news-date-january-30-2024",
          date: "January 30, 2024",
          filePath: "/2024/wafd-bank-press-release-20240130.pdf",
          text: "WaFd, Inc. Receives Regulatory Approval for Acquisition of Luther Burbank Corporation"
        },
        {
          id: "financial-news-link-january-16-2024",
          dateId: "financial-news-date-january-16-2024",
          date: "January 16, 2024",
          filePath: "/2024/wafd-bank-press-release-20240116.pdf",
          text: "WaFd Announces Quarterly Earnings Per Share Of $0.85 (Fact Sheet follows on Page 12)"
        }
      ]
    },
    {
      year: 2023,
      newsData: [
        {
          id: "financial-news-link-november-14-2023",
          dateId: "financial-news-date-november-14-2023",
          date: "November 14, 2023",
          filePath: "/2023/wafd-bank-press-release-20231114.pdf",
          text: "WaFd Announces Cash Dividend of 25 cents per share"
        },
        {
          id: "financial-news-link-october-17-2023",
          dateId: "financial-news-date-october-17-2023",
          date: "October 17, 2023",
          filePath: "/2023/wafd-bank-press-release-20231017.pdf",
          text: "WaFd's Annual Earnings Per Share Increased 10% For 2023 Even After Net Interest Margin Contraction and Outsized Provision For Credit Losses (Fact Sheet follows on Page 12)"
        },
        {
          id: "financial-news-link-august-18-2023",
          dateId: "financial-news-date-august-18-2023",
          date: "August 18, 2023",
          filePath: "/2023/wafd-bank-investor-presentation-20230818.pdf",
          text: "Investor Presentation As of 8/18/2023"
        },
        {
          id: "financial-news-link-august-15-2023",
          dateId: "financial-news-date-august-15-2023",
          date: "August 15, 2023",
          filePath: "/2023/wafd-bank-press-release-20230815.pdf",
          text: "Washington Federal Announces Cash Dividend of 25 cents per share"
        },
        {
          id: "financial-news-link-august-07-2023",
          dateId: "financial-news-date-august-07-2023",
          date: "August 7, 2023",
          filePath: "/2023/wafd-bank-press-release-20230807.pdf",
          text: "WaFd Bank Scores Highest Customer Satisfaction Ranking in Annual Survey"
        },
        {
          id: "financial-news-link-july-14-2023",
          dateId: "financial-news-date-july-14-2023",
          date: "July 14, 2023",
          filePath: "/2023/wafd-bank-press-release-20230714.pdf",
          text: "Washington Federal Announces Quarterly Earnings Per Share Of $0.89 (Fact Sheet follows on Page 12)"
        },
        {
          id: "financial-news-link-may-09-2023",
          dateId: "financial-news-date-may-09-2023",
          date: "May 9, 2023",
          filePath: "/2023/wafd-bank-press-release-20230509.pdf",
          text: "Washington Federal Announces Cash Dividend of 25 Cents Per Share"
        },
        {
          id: "financial-news-link-may-05-2023",
          dateId: "financial-news-date-may-05-2023",
          date: "May 5, 2023",
          filePath: "/2023/wafd-bank-press-release-20230505.pdf",
          text: "Washington Federal, Inc. and Luther Burbank Corporation Announce Receipt of Shareholder Approval For Merger"
        },
        {
          id: "financial-news-link-may-04-2023",
          dateId: "financial-news-date-may-04-2023",
          date: "May 4, 2023",
          externalUrl: true,
          url: "https://central.virtualshareholdermeeting.com/vsm/web?pvskey=WAFD2023SM",
          text: "WASHINGTON FEDERAL, INC.'s Special Shareholders Meeting on Luther Burbank Corporation Shareholder Approval For Merger is now accessible at https://central.virtualshareholdermeeting.com/vsm/web?pvskey=WAFD2023SM"
        },
        {
          id: "financial-news-link-april-18-2023",
          dateId: "financial-news-date-april-18-2023",
          date: "April 18, 2023",
          url: "https://storm.wnba.com/news/storm-partners-with-wafd-bank",
          externalUrl: true,
          text: "Seattle Storm and WaFd Bank Partner to Support Title I Schools"
        },
        {
          id: "financial-news-link-april-13-2023",
          dateId: "financial-news-date-april-13-2023",
          date: "April 13, 2023",
          filePath: "/2023/wafd-bank-press-release-20230413.pdf",
          text: "Washington Federal Announces Quarterly Earnings Per Share Of $0.95 (Fact Sheet follows on Page 12)"
        },
        {
          id: "financial-news-link-march-23-2023",
          dateId: "financial-news-date-march-23-2023",
          date: "March 23, 2023",
          filePath: "/2023/wafd-bank-press-release-20230323.pdf",
          text: "Bank with Confidence at WaFd Bank"
        },
        {
          id: "financial-news-link-march-01-2023",
          dateId: "financial-news-date-march-01-2023",
          date: "March 1, 2023",
          filePath: "/2023/wafd-bank-press-release-20230301.pdf",
          text: "WaFd Bank Hires Veteran Banker Mark Borrecco as Regional President for California"
        },
        {
          id: "financial-news-link-february-14-2023",
          dateId: "financial-news-date-february-14-2023",
          date: "February 14, 2023",
          filePath: "/2023/wafd-bank-press-release-20230214.pdf",
          text: "Washington Federal Announces Increase in its Cash Dividend to 25 cents per share"
        },
        {
          id: "financial-news-link-january-26-2023",
          dateId: "financial-news-date-january-26-2023",
          date: "January 26, 2023",
          url: "/about-us/investor-relations/financial-news/brent-beardall-expected-return",
          text: "WaFd Bank President and CEO Brent Beardall Expected to Return Soon to Full-Time Duties"
        },
        {
          id: "financial-news-link-january-12-2023",
          dateId: "financial-news-date-january-12-2023",
          date: "January 12, 2023",
          filePath: "/2023/wafd-bank-press-release-20230112.pdf",
          text: "Washington Federal Announces Record Quarterly Earnings Per Share Of $1.16 (Fact Sheet follows on Page 11)"
        },
        {
          id: "financial-news-link-january-03-2023-2",
          dateId: "financial-news-date-january-03-2023-2",
          date: "January 3, 2023",
          filePath: "/2023/letter-brent-beardall-01032023.pdf",
          text: "Update on Brent Beardall's Condition"
        },
        {
          id: "financial-news-link-january-03-2023-1",
          dateId: "financial-news-date-january-03-2023-1",
          date: "January 3, 2023",
          url: "/about-us/investor-relations/financial-news/brent-beardall-in-stable-condition",
          text: "WaFd Bank President and CEO Brent Beardall in Stable Condition After Being Injured in Small Plane Crash"
        }
      ]
    },
    {
      year: 2022,
      newsData: [
        {
          id: "financial-news-link-december-13-2022",
          dateId: "financial-news-date-december-13-2022",
          date: "December 13, 2022",
          filePath: "/2022/wafd-bank-investor-presentation-20221213.pdf",
          text: "Investor Presentation, September 30th, 2022"
        },
        {
          id: "financial-news-link-november-14-2022",
          dateId: "financial-news-date-november-14-2022",
          date: "November 14, 2022",
          filePath: "/2022/wafd-bank-press-release-20221114.pdf",
          text: "Washington Federal, Inc. Luther Burbank Corporation Conference Call Transcript"
        },
        {
          id: "financial-news-link-november-13-2022-2",
          dateId: "financial-news-date-november-13-2022-2",
          date: "November 13, 2022",
          filePath: "/2022/wafd-bank-press-release-20221113.pdf",
          text: "Washington Federal, Inc. and Luther Burbank Corporation Announce Definitive Merger Agreement to form a $29 Billion Asset Western US Bank"
        },
        {
          id: "financial-news-link-november-13-2022-1",
          dateId: "financial-news-date-november-13-2022-1",
          date: "November 13, 2022",
          filePath: "/2022/wafd-bank-investor-presentation-20221113.pdf",
          text: "Washington Federal, Inc. and Luther Burbank Corporation Investor Presentation"
        },
        {
          id: "financial-news-link-november-08-2022",
          dateId: "financial-news-date-november-08-2022",
          date: "November 8, 2022",
          filePath: "/2022/wafd-bank-press-release-20221108.pdf",
          text: "Washington Federal Announces Cash Dividend of 24 Cents Per Share"
        },
        {
          id: "financial-news-link-october-13-2022",
          dateId: "financial-news-date-october-13-2022",
          date: "October 13, 2022",
          filePath: "/2022/wafd-bank-press-release-20221013.pdf",
          text: "Washington Federal Reports Record Earnings Per Share Increase of 42% For 2022 (Fact Sheet follows on Page 11)"
        },
        {
          id: "financial-news-link-september-28-2022",
          dateId: "financial-news-date-september-28-2022",
          date: "September 28, 2022",
          filePath: "/2022/wafd-bank-press-release-20220928.pdf",
          text: "WaFd Bank Promotes Veteran Banker Dan LaCoste to Regional President for Southern Oregon"
        },
        {
          id: "financial-news-link-september-14-2022",
          dateId: "financial-news-date-september-14-2022",
          date: "September 14, 2022",
          filePath: "/2022/wafd-bank-investor-presentation-20220914.pdf",
          text: "Investor Presentation, September 14, 2022"
        },
        {
          id: "financial-news-link-august-31-2022",
          dateId: "financial-news-date-august-31-2022",
          date: "August 31, 2022",
          filePath: "/2022/wafd-bank-press-release-20220831.pdf",
          text: "WaFd Bank Promotes Chief Risk Officer Kelli Holz to Chief Financial Officer"
        },
        {
          id: "financial-news-link-august-16-2022",
          dateId: "financial-news-date-august-16-2022",
          date: "August 16, 2022",
          filePath: "/2022/wafd-bank-investor-presentation-20220816.pdf",
          text: "Investor Presentation, August 16, 2022"
        },
        {
          id: "financial-news-link-august-09-2022",
          dateId: "financial-news-date-august-09-2022",
          date: "August 09, 2022",
          filePath: "/2022/wafd-bank-press-release-20220809.pdf",
          text: "Washington Federal Announces Cash Dividend of 24 Cents Per Share"
        },
        {
          id: "financial-news-link-july-13-2022",
          dateId: "financial-news-date-july-13-2022",
          date: "July 13, 2022",
          filePath: "/2022/wafd-bank-press-release-20220713.pdf",
          text: "Washington Federal Announces Quarterly Earnings Per Share Of $0.91 (Fact Sheet follows on Page 11)"
        },
        {
          id: "financial-news-link-may-10-2022",
          dateId: "financial-news-date-may-10-2022",
          date: "May 10, 2022",
          filePath: "/2022/wafd-bank-press-release-20220510.pdf",
          text: "Washington Federal Announces Cash Dividend of 24 Cents Per Share"
        },
        {
          id: "financial-news-link-may-4-2022",
          dateId: "financial-news-date-may-4-2022",
          date: "May 4, 2022",
          filePath: "/2022/wafd-bank-investor-presentation-20220504.pdf",
          text: "Investor Presentation, May 4th-6th, 2022"
        },
        {
          id: "financial-news-link-april-27-2022",
          dateId: "financial-news-date-april-27-2022",
          date: "April 27, 2022",
          filePath: "/2022/wafd-bank-press-release-20220427.pdf",
          text: "WaFd Bank Promotes Veteran Banker Todd Gerber to Regional President for Arizona"
        },
        {
          id: "financial-news-link-april-19-2022",
          dateId: "financial-news-date-april-19-2022",
          date: "April 19, 2022",
          filePath: "/2022/wafd-bank-press-release-20220419.pdf",
          text: "WaFd Bank Teams with Plaid to Give Banking Clients Secure Seamless Access to Financial Solutions"
        },
        {
          id: "financial-news-link-april-13-2022",
          dateId: "financial-news-date-april-13-2022",
          date: "April 13, 2022",
          filePath: "/2022/wafd-bank-press-release-20220413.pdf",
          text: "Washington Federal Announces Quarterly Earnings Per Share Of $0.70 (Fact Sheet follows on Page 11)"
        },
        {
          id: "financial-news-link-april-5-2022",
          dateId: "financial-news-date-april-5-2022",
          date: "April 5, 2022",
          filePath: "/2022/wafd-bank-press-release-20220405.pdf",
          text: "WaFd Bank Promotes Banking Veteran Doron Joseph to Regional President for Nevada"
        },
        {
          id: "financial-news-link-march-8-2022",
          dateId: "financial-news-date-march-8-2022",
          date: "March 8, 2022",
          filePath: "/2022/wafd-bank-investor-presentation-20220308.pdf",
          text: "Investor Presentation, March 8, 2022"
        },
        {
          id: "financial-news-link-february-16-2022",
          dateId: "financial-news-date-february-16-2022",
          date: "February 16, 2022",
          filePath: "/2022/wafd-bank-investor-presentation-20220216.pdf",
          text: "Investor Presentation, February 16, 2022"
        },
        {
          id: "financial-news-link-february-4-2022",
          dateId: "financial-news-date-february-4-2022",
          date: "February 4, 2022",
          filePath: "/2022/wafd-bank-press-release-20220204.pdf",
          text: "Washington Federal Bank Announces Completion of Conversion to Washington State Chartered Commercial Bank"
        },
        {
          id: "financial-news-link-january-25-2022",
          dateId: "financial-news-date-january-25-2022",
          date: "January 25, 2022",
          filePath: "/2022/wafd-bank-press-release-20220125.pdf",
          text: "Washington Federal Announces Increase in its Cash Dividend and Completion of its Board Succession Plan"
        },
        {
          id: "financial-news-link-january-13-2022",
          dateId: "financial-news-date-january-13-2022",
          date: "January 13, 2022",
          filePath: "/2022/wafd-bank-press-release-20220113.pdf",
          text: "Washington Federal Announces Quarterly Earnings Per Share Of $0.71 (Fact Sheet follows on Page 9)"
        }
      ],
      id: "financial-news-2022"
    },
    {
      year: 2021,
      newsData: [
        {
          id: "financial-news-link-december-20-2021",
          dateId: "financial-news-date-december-20-2021",
          date: "December 20, 2021",
          filePath: "/2021/wafd-bank-press-release-20211220.pdf",
          text: "Washington Federal Bank, N.A. Announces Termination Of AML/BSA Consent Order"
        },
        {
          id: "financial-news-link-november-16-2021",
          dateId: "financial-news-date-november-16-2021",
          date: "November 16, 2021",
          filePath: "/2021/wafd-bank-press-release-20211116.pdf",
          text: "Investor Presentation, November 16, 2021"
        },
        {
          id: "financial-news-link-october-26-2021",
          dateId: "financial-news-date-october-26-2021",
          date: "October 26, 2021",
          filePath: "/2021/wafd-bank-press-release-20211026.pdf",
          text: "Washington Federal Announces 23 Cent Per Share Cash Dividend"
        },
        {
          id: "financial-news-link-october-14-2021",
          dateId: "financial-news-date-october-14-2021",
          date: "October 14, 2021",
          filePath: "/2021/wafd-bank-sep-2021-earnings-release-and-fact-sheet.pdf",
          text: "Washington Federal Reports Earnings Per Share Of $2.39 For Fiscal 2021 (Fact Sheet follows on Page 10)"
        },
        {
          id: "financial-news-link-september-30-2021",
          dateId: "financial-news-date-september-30-2021",
          date: "September 30, 2021",
          filePath: "/2021/wafd-bank-cmp-press-release.pdf",
          text: "Washington Federal Bank, N.A. Agrees to Civil Money Penalty for AML/BSA Consent Order"
        },
        {
          id: "financial-news-link-august-17-2021",
          dateId: "financial-news-date-august-17-2021",
          date: "August 17, 2021",
          filePath: "/2021/wafd-bank-investor-presentation-da-davidson-aug-17-2021.pdf",
          text: "Investor Presentation, August 17, 2021"
        },
        {
          id: "financial-news-link-july-27-2021",
          dateId: "financial-news-date-july-27-2021",
          date: "July 27, 2021",
          filePath: "/2021/wafd-bank-07-2021-dividend-release.pdf",
          text: "Washington Federal Announces 23 Cent Per Share Cash Dividend"
        },
        {
          id: "financial-news-link-july-13-2021",
          dateId: "financial-news-date-july-13-2021",
          date: "July 13, 2021",
          filePath: "/2021/wafd-bank-july-2021-earnings-release-and-fact-sheet.pdf",
          text: "Washington Federal Announces Quarterly Earnings Per Share Of $0.61 (Fact Sheet follows on Page 9)"
        },
        {
          id: "financial-news-link-july-1-2021",
          dateId: "financial-news-date-july-1-2021",
          date: "July 1, 2021",
          filePath: "/2021/wafd-bank-press-release-bod-succession-final-july1.pdf",
          text: "Washington Federal Announces Board Succession Plans"
        },
        {
          id: "financial-news-link-may-5-2021",
          dateId: "financial-news-date-may-5-2021",
          date: "May 5, 2021",
          filePath: "/2021/wafd-bank-investor-presentation-davidson-may-2021.pdf",
          text: "Investor Presentation, May 5, 2021"
        },
        {
          id: "financial-news-link-april-27-2021",
          dateId: "financial-news-date-april-27-2021",
          date: "April 27, 2021",
          filePath: "/2021/wafd-bank-04-2021-dividend-release.pdf",
          text: "Washington Federal Announces 23 Cent Per Share Cash Dividend"
        },
        {
          id: "financial-news-link-april-13-2021",
          dateId: "financial-news-date-april-13-2021",
          date: "April 13, 2021",
          filePath: "/2021/wafd-bank-march-2021-earnings-release-fInal-and-fact-sheet.pdf",
          text: "Washington Federal Announces Quarterly Earnings Per Share Of $0.56 (Fact Sheet follows on Page 10)"
        },
        {
          id: "financial-news-link-march-12-2021",
          dateId: "financial-news-date-march-12-2021",
          date: "March 12, 2021",
          filePath: "/2021/wafd-bank-final-results-of-tender-offer.pdf",
          text: "Washington Federal, Inc. Announces Final Results of Tender Offer"
        },
        {
          id: "financial-news-link-march-10-2021",
          dateId: "financial-news-date-march-10-2021",
          date: "March 10, 2021",
          filePath: "/2021/wafd-bank-preliminary-results-of-tender-offer.pdf",
          text: "Washington Federal, Inc. Announces Preliminary Results of Tender Offer"
        },
        {
          id: "financial-news-link-february-9-2021",
          dateId: "financial-news-date-february-9-2021",
          date: "February 9, 2021",
          filePath: "/2021/wafd-bank-dutch-auction-stock-offering.pdf",
          text: "Washington Federal, Inc. Commences a Modified Dutch Auction Tender Offer to Repurchase up to $290 Million of its Common Stock"
        },
        {
          id: "financial-news-link-february-1-2021",
          dateId: "financial-news-date-february-1-2021",
          date: "February 1, 2021",
          filePath: "/2021/wafd-bank-pricing-press-release.pdf",
          text: "Washington Federal, Inc. Announces Pricing of $300,000,000 Depositary Shares Offering"
        },
        {
          id: "financial-news-link-january-28-2021",
          dateId: "financial-news-date-january-28-2021",
          date: "January 28, 2021",
          element: (
            <p>
              Moody's assigns first-time A1 long-term deposit rating to Washington Federal Bank; outlook stable.{" "}
              <a
                id="jan-28-2021-read-more"
                href="https://www.moodys.com/research/Moodys-assigns-first-time-A1-long-term-deposit-rating-to--PR_439529"
                target="_blank"
                rel="noopener noreferrer"
              >
                Read more
              </a>
              .
            </p>
          )
        },
        {
          id: "financial-news-link-january-26-2021",
          dateId: "financial-news-date-january-26-2021",
          date: "January 26, 2021",
          filePath: "/2021/wafd-01-2021-dividend-release.pdf",
          text: "Washington Federal Announces 4.5% Increase in its Cash Dividend to 23 Cents Per Share"
        },
        {
          id: "financial-news-link-january-14-2021",
          dateId: "financial-news-date-january-14-2021",
          date: "January 14, 2021",
          filePath: "/2021/Exhibit-99.1-December-2020-Earnings-Release-with-Supplement-Final-and-Fact-Sheet.pdf",
          text: "Washington Federal Announces Quarterly Earnings Per Share Of $0.51 (Earnings Release Supplemental follows on page 9, Fact Sheet follows on Page 20)"
        }
      ],
      id: "financial-news-2021"
    },
    {
      year: 2020,
      newsData: [
        {
          id: "financial-news-link-november-19-2020",
          dateId: "financial-news-date-november-19-2020",
          date: "November 19, 2020",
          filePath: "/2020/wafd-bank-investor-presentation-kbw-nov-19-2020.pdf",
          text: "Investor Presentation, November 19, 2020"
        },
        {
          id: "financial-news-link-october-27-2020",
          dateId: "financial-news-date-october-27-2020",
          date: "October 27, 2020",
          filePath: "/2020/wafd-bank-10-2020-dividend-release.pdf",
          text: "Washington Federal Announces 22 Cent Per Share Cash Dividend"
        },
        {
          date: "October 14, 2020",
          id: "financial-news-link-october-14-2020-1",
          dateId: "financial-news-date-october-14-2020-1",
          filePath: "/2020/wafd-bank-click-here-for-specific-details-on-our-financial-results-and-reporting-sep-30.pdf",
          text: "Washington Federal's Comprehensive Fact Sheet 9/30/2020"
        },
        {
          date: "October 14, 2020",
          id: "financial-news-link-october-14-2020-2",
          dateId: "financial-news-date-october-14-2020-2",
          filePath: "/2020/wafd-bank-sep-2020-earnings-release_with-supplement.pdf",
          text: "Washington Federal Reports Earnings Per Share Of $2.26 For Fiscal 2020 (Earnings Release Supplemental follows on page 9)"
        },
        {
          id: "financial-news-link-july-28-2020",
          dateId: "financial-news-date-july-28-2020",
          date: "July 28, 2020",
          filePath: "/2020/wafd-bank-7-2020_dividend-release.pdf",
          text: "Washington Federal Announces 22 Cent Per Share Cash Dividend"
        },
        {
          date: "July 22, 2020",
          id: "financial-news-link-july-22-2020-1",
          dateId: "financial-news-date-july-22-2020-1",
          filePath: "/2020/wafd-bank-click-here-for-specific-details-on-our-financial-results-and-reporting.pdf",
          text: "Washington Federal's Comprehensive Fact Sheet 6/30/2020"
        },
        {
          date: "July 22, 2020",
          id: "financial-news-link-july-22-2020-2",
          dateId: "financial-news-date-july-22-2020-2",
          filePath: "/2020/wafd-bank-exhibit-99-1_june-2020-earnings-release-and-supplement-final.pdf",
          text: "Washington Federal Announces Quarterly Earnings Per Share Of $0.46 (Earnings Release Supplemental follows on page 10)"
        },
        {
          id: "financial-news-link-may-6-2020",
          dateId: "financial-news-date-may-6-2020",
          date: "May 6, 2020",
          filePath: "/2020/wafd-bank-investor-presentation-da-davidson-may-6-2020.pdf",
          text: "Investor Presentation, May 6, 2020"
        },
        {
          id: "financial-news-link-april-28-2020",
          dateId: "financial-news-date-april-28-2020",
          date: "April 28, 2020",
          filePath: "/2020/wafd-bank-4-2020_dividend_release.pdf",
          text: "Washington Federal Announces 22 Cent Per Share Cash Dividend"
        },
        {
          id: "financial-news-link-april-21-2020",
          dateId: "financial-news-date-april-21-2020",
          date: "April 21, 2020",
          filePath: "/2020/wafd-bank-exhibit-99-1_march-2020-earnings-release-and-earnings-release-supplement.pdf",
          text: "Washington Federal Announces Quarterly Earnings Per Share Of $0.49 and Earnings Release Supplemental Covid-19"
        },
        {
          id: "financial-news-link-april-15-2020",
          dateId: "financial-news-date-april-15-2020",
          date: "April 15, 2020",
          filePath: "/2020/wafd-bank-sba-ppp-release.pdf",
          text: "WaFd Bank Open and Ready to Help Small Business and Individuals"
        },
        {
          id: "financial-news-link-march-12-2020",
          dateId: "financial-news-date-march-12-2020",
          date: "March 12, 2020",
          filePath: "/2020/wafd-bank-small-business-lifeline-release.pdf",
          text: "WaFd Bank Launches Small Business Lifeline in Response to Coronavirus"
        },
        {
          date: "January 22, 2020",
          id: "financial-news-link-january-22-2020-1",
          dateId: "financial-news-date-january-22-2020-1",
          filePath: "/2020/wafd-bank-1-2020_dividend-release-final-2.pdf",
          text: "Washington Federal Announces 5% Increase in its Cash Dividend"
        },
        {
          date: "January 22, 2020",
          id: "financial-news-link-january-22-2020-2",
          dateId: "financial-news-date-january-22-2020-2",
          filePath: "/2020/wafd-bank-jan-2020-annual-meeting-final.pdf",
          text: "View the 2020 Annual Shareholder's Meeting Slides"
        },
        {
          id: "financial-news-link-january-14-2020",
          dateId: "financial-news-date-january-14-2020",
          date: "January 14, 2020",
          filePath: "/2020/wafd-bank-exhibit99-1_december-2019-earnings-release-(19).pdf",
          text: "Washington Federal Announces Quarterly Earnings Per Share Of $0.84"
        }
      ],
      id: "financial-news-2020"
    },
    {
      year: 2019,
      newsData: [
        {
          id: "financial-news-link-september-30-2019",
          dateId: "financial-news-date-september-30-2019",
          date: "September 30, 2019",
          filePath: "/2019/wafd-investor-presentation-sept-30-2019.pdf",
          text: "Investor Presentation, September 30, 2019"
        },
        {
          id: "financial-news-link-october-29-2019",
          dateId: "financial-news-date-october-29-2019",
          date: "October 29, 2019",
          filePath: "/2019/wafd_10-2019_dividend-release.pdf",
          text: "Washington Federal Announces 21 Cent Per Share Cash Dividend"
        },
        {
          id: "financial-news-link-october-15-2019",
          dateId: "financial-news-date-october-15-2019",
          date: "October 15, 2019",
          filePath: "/2019/exhibit_99-1_sep_2019_earnings_release_r35.pdf",
          text: "Washington Federal Reports Record Earnings Per Share Of $2.61"
        },
        {
          id: "financial-news-link-october-1-2019",
          dateId: "financial-news-date-october-1-2019",
          date: "October 1, 2019",
          filePath: "/2019/management-succession-announcement_mauer_endrizzi-update.pdf",
          text: "Washington Federal Bank Announces Next Phase of Management Succession"
        },
        {
          id: "financial-news-link-august-12-2019",
          dateId: "financial-news-date-august-12-2019",
          date: "August 12, 2019",
          filePath: "/2019/wafd-investor-presentation-jun-30-2019.pdf",
          text: "Investor Presentation, June 30, 2019"
        },
        {
          id: "financial-news-link-july-22-2019",
          dateId: "financial-news-date-july-22-2019",
          date: "July 22, 2019",
          filePath: "/2019/wafd_7-2019_dividend-release.pdf",
          text: "Washington Federal Bank Announces a 5% Increase in Cash Dividend"
        },
        {
          id: "financial-news-link-july-19-2019",
          dateId: "financial-news-date-july-19-2019",
          date: "July 19, 2019",
          filePath: "/2019/linda-brower-press-release.pdf",
          text: "Washington Federal Bank Announces the Appointment of Former Executive Linda Brower to its Board of Directors"
        },
        {
          id: "financial-news-link-july-16-2019",
          dateId: "financial-news-date-july-16-2019",
          date: "July 16, 2019",
          filePath: "/2019/exhibit_99-1_june_2019_earnings_release_final.pdf",
          text: "Washington Federal Announces Record Quarterly Earnings Per Share Of $0.67"
        },
        {
          id: "financial-news-link-may-6-2019",
          dateId: "financial-news-date-may-6-2019",
          date: "May 6, 2019",
          filePath: "/2019/wafd-investor-presentation-mar-31-2019-(002).pdf",
          text: "Investor Presentation, March 31, 2019"
        },
        {
          id: "financial-news-link-april-29-2019",
          dateId: "financial-news-date-april-29-2019",
          date: "April 29, 2019",
          filePath: "/2019/wafd_4-2019_dividend-release.pdf",
          text: "Washington Federal Announces 20 Cent Per Share Cash Dividend"
        },
        {
          id: "financial-news-link-april-25-2019",
          dateId: "financial-news-date-april-25-2019",
          date: "April 25, 2019",
          filePath: "/2019/stephen-graham-appointment-final.pdf",
          text: "Washington Federal Announces Appointment of Attorney Stephen Graham to its Board of Directors"
        },
        {
          id: "financial-news-link-april-15-2019",
          dateId: "financial-news-date-april-15-2019",
          date: "April 15, 2019",
          filePath: "/2019/exhibit_99-1_mar_2019_earnings_release_r40_final.pdf",
          text: "Washington Federal Announces Quarterly Earnings Per Share Of $0.63"
        },
        {
          id: "financial-news-link-february-14-2019",
          dateId: "financial-news-date-february-14-2019",
          date: "February 14, 2019",
          filePath: "/2019/wafd-kbw-investor-presentation.pdf",
          text: "Washington Federal Investor Presentation"
        },
        {
          id: "financial-news-link-january-16-2019",
          dateId: "financial-news-date-january-16-2019",
          date: "January 16, 2019",
          filePath:
            "/2019/washington-federal-announces-11-increase-in-its-cash-dividend-and-an-increase-in-share-repurchase-authorization.pdf",
          text: "Washington Federal Announces 11% Increase in its Cash Dividend and an Increase in Share Repurchase Authorization"
        },
        {
          date: "January 15, 2019",
          id: "financial-news-link-january-15-2019-1",
          dateId: "financial-news-date-january-15-2019-1",
          filePath:
            "/2019/washington-federal-announces-a-quick-90-day-interest-free-loan-to-help-americans-during-the-federal-government-shutdown.pdf",
          text: "Washington Federal Announces a Quick 90 Day Interest-Free Loan to Help Americans during the Federal Government Shutdown "
        },
        {
          date: "January 15, 2019",
          id: "financial-news-link-january-15-2019-2",
          dateId: "financial-news-date-january-15-2019-2",
          filePath: "/2019/washington-federal-announces-quarterly-earnings-per-share-of-$0-65.pdf",
          text: "Washington Federal Announces Quarterly Earnings Per Share of $0.65"
        },
        {
          id: "financial-news-link-january-8-2019",
          dateId: "financial-news-date-january-8-2019",
          date: "January 8, 2019",
          filePath: "/2019/boise-bodybuilding-com-property-acquisiiton.pdf",
          text: "Washington Federal Expands in Idaho Acquiring Bodybuilding.com Property"
        }
      ],
      id: "financial-news-2019"
    },
    {
      year: 2018,
      newsData: [
        {
          id: "financial-news-link-november-2018",
          dateId: "financial-news-date-november-2018",
          date: "November 2018",
          filePath: "/2018/washington-federal-investor-presentation.pdf",
          text: "Washington Federal Investor Presentation"
        },
        {
          id: "financial-news-link-october-23-2018",
          dateId: "financial-news-date-october-23-2018",
          date: "October 23, 2018",
          filePath: "/2018/washington-federal-announces-board-chairman-appointment.pdf",
          text: "Washington Federal Announces Board Chairman Appointment"
        },
        {
          id: "financial-news-link-october-22-2018",
          dateId: "financial-news-date-october-22-2018",
          date: "October 22, 2018",
          filePath: "/2018/washington-federal-announces-18-cent-per-share-cash-dividend.pdf",
          text: "Washington Federal Announces 18 Cent Per Share Cash Dividend"
        },
        {
          id: "financial-news-link-october-16-2018",
          dateId: "financial-news-date-october-16-2018",
          date: "October 16, 2018",
          filePath: "/2018/washington-federal-reports-record-earnings.pdf",
          text: "Washington Federal Reports Record Earnings"
        },
        {
          id: "financial-news-link-july-23-2018",
          dateId: "financial-news-date-july-23-2018",
          date: "July 23, 2018",
          filePath: "/2018/wafd_7-2018_dividend-release.pdf",
          text: "Washington Federal Announces 6% Increase in its Cash Dividend"
        },
        {
          id: "financial-news-link-july-18-2018",
          dateId: "financial-news-date-july-18-2018",
          date: "July 18, 2018",
          filePath: "/2018/washington-federal-announces-record-quarterly-earnings-per-share-of-$0-61.pdf",
          text: "Washington Federal Announces Record Quarterly Earnings Per Share Of $0.61"
        },
        {
          id: "financial-news-link-july-10-2018",
          dateId: "financial-news-date-july-10-2018",
          date: "July 10, 2018",
          filePath: "/2018/wafd_07-2018_singh-press-release.pdf",
          text: "Washington Federal Announces Appointment of Technology Leader Steve Singh to its Board of Directors"
        },
        {
          id: "financial-news-link-april-30-2018",
          dateId: "financial-news-date-april-30-2018",
          date: "April 30, 2018",
          filePath: "/2018/washington-federal-announces-cash-dividend-of-17-cents-per-share.pdf",
          text: "Washington Federal Announces Cash Dividend of 17 Cents Per Share"
        },
        {
          id: "financial-news-link-april-11-2018",
          dateId: "financial-news-date-april-11-2018",
          date: "April 11, 2018",
          filePath: "/2018/march-2018-earnings-release.pdf",
          text: "Washington Federal Announces Quarterly Earnings Per Share Of $0.57"
        },
        {
          id: "financial-news-link-march-26-2018",
          dateId: "financial-news-date-march-26-2018",
          date: "March 26, 2018",
          filePath: "/2018/washington-federal-announces-board-succession-plan-update.pdf",
          text: "Washington Federal Announces Board Succession Plan Update"
        },
        {
          id: "financial-news-link-february-28-2018",
          dateId: "financial-news-date-february-28-2018",
          date: "February 28, 2018",
          filePath: "/2018/sounders-fc-and-washington-federal-announce-new-partnership.pdf",
          text: "Sounders FC And Washington Federal Announce New Partnership"
        },
        {
          id: "financial-news-link-january-24-2018",
          dateId: "financial-news-date-january-24-2018",
          date: "January 24, 2018",
          filePath:
            "/2018/washington-federal-announces-13-increase-in-its-cash-dividend-and-an-increase-in-share-repurchase-authorization.pdf",
          text: "Washington Federal Announces 13% Increase in its Cash Dividend and an Increase in Share Repurchase Authorization"
        },
        {
          id: "financial-news-link-january-17-2018",
          dateId: "financial-news-date-january-17-2018",
          date: "January 17, 2018",
          filePath: "/2018/january-2018-earnings-release.pdf",
          text: "Washington Federal Announces Record Quarterly Earnings"
        },
        {
          id: "financial-news-link-january-5-2018",
          dateId: "financial-news-date-january-5-2018",
          date: "January 5, 2018",
          filePath:
            "/2018/washington-federal-promotes-bryan-lutz-as-its-new-northern-washington-regional-president.pdf",
          text: "Washington Federal Promotes Bryan Lutz as its new Northern Washington Regional President"
        }
      ],
      id: "financial-news-2018"
    },
    {
      year: 2017,
      newsData: [
        {
          id: "financial-news-link-december-12-2017",
          dateId: "financial-news-date-december-12-2017",
          date: "December 12, 2017",
          filePath: "/2017/wafd_12-2017_washington-federal-investment.pdf",
          text: "Washington Federal Responds to Tax Reform with Accelerated Investments in Employees, Technology and Community Development"
        },
        {
          id: "financial-news-link-november-27-2017",
          dateId: "financial-news-date-november-27-2017",
          date: "November 27, 2017",
          filePath: "/2017/washington-federal-announces-michelle-coons-as-its-new-new-mexico-regional-president.pdf",
          text: "Washington Federal Announces Michelle Coons as its new New Mexico Regional President"
        },
        {
          id: "financial-news-link-october-25-2017",
          dateId: "financial-news-date-october-25-2017",
          date: "October 25, 2017",
          filePath: "/2017/wafd_10-2017_dividend-release.pdf",
          text: "Washington Federal Announces Cash Dividend of 15 Cents Per Share"
        },
        {
          id: "financial-news-link-october-18-2017",
          dateId: "financial-news-date-october-18-2017",
          date: "October 18, 2017",
          filePath: "/2017/washington-federal-reports-record-earnings.pdf",
          text: "Washington Federal Reports Record Earnings"
        },
        {
          id: "financial-news-link-september-27-2017",
          dateId: "financial-news-date-september-27-2017",
          date: "September 27, 2017",
          filePath: "/2017/wafd-anchor-press-release-sep-27-2017-final-3.pdf",
          text: "Washington Federal and Anchor Bancorp Announce Extension of Merger Agreement"
        },
        {
          id: "financial-news-link-august-3-2017",
          dateId: "financial-news-date-august-3-2017",
          date: "August 3, 2017",
          filePath: "/2017/aug-2017-warrants-strike-price.pdf",
          text: "Washington Federal Announces Adjustments to Warrant Exercise Price and Shares Receivable upon Warrant Exercise"
        },
        {
          id: "financial-news-link-july-24-2017",
          dateId: "financial-news-date-july-24-2017",
          date: "July 24, 2017",
          filePath: "/2017/wafd_07-2017_dividend-release.pdf",
          text: "Washington Federal Announces Cash Dividend of 15 Cents Per Share"
        },
        {
          id: "financial-news-link-july-18-2017",
          dateId: "financial-news-date-july-18-2017",
          date: "July 18, 2017",
          filePath: "/2017/washington-federal-announces-5percent-increase-in-quarterly-earnings-per-share.pdf",
          text: "Washington Federal Announces 5% Increase In Quarterly Earnings Per Share"
        },
        {
          date: "April 24, 2017",
          id: "financial-news-link-april-24-2017-1",
          dateId: "financial-news-date-april-24-2017-1",
          filePath: "/2017/wafd_04-2017_dividend-release.pdf",
          text: "Washington Federal Announces Cash Dividend of 15 Cents Per Share"
        },
        {
          date: "April 24, 2017",
          id: "financial-news-link-april-24-2017-2",
          dateId: "financial-news-date-april-24-2017-2",
          filePath: "/2017/wafd_04-2017_first-100-years.pdf",
          text: "Washington Federal Celebrates First 100 Years"
        },
        {
          id: "financial-news-link-april-13-2017",
          dateId: "financial-news-date-april-13-2017",
          date: "April 13, 2017",
          filePath: "/2017/exhibit-99-1_mar-2017-earnings-release-r33-no-shading-final.pdf",
          text: "Washington Federal Announces 4% Increase In Quarterly Earnings"
        },
        {
          id: "financial-news-link-april-11-2017",
          dateId: "financial-news-date-april-11-2017",
          date: "April 11, 2017",
          filePath: "/2017/wafd_04-2017_washington-federal-inc-to-acquire-anchor-bancorp.pdf",
          text: "Washington Federal, Inc. to Acquire Anchor Bancorp for $63.9 Million in Stock"
        },
        {
          id: "financial-news-link-march-27-2017",
          dateId: "financial-news-date-march-27-2017",
          date: "March 27, 2017",
          filePath: "/2017/wafd_03-2017_kim-robison.pdf",
          text: "Washington Federal Promotes Kim Robison to Manage Operations"
        },
        {
          id: "financial-news-link-january-18-2017",
          dateId: "financial-news-date-january-18-2017",
          date: "January 18, 2017",
          filePath: "/2017/wafd_01_2017_dividend-release.pdf",
          text: "Washington Federal Announces an Increase in its Regular Quarterly Cash Dividend and a Special Cash Dividend"
        },
        {
          id: "financial-news-link-january-17-2017",
          dateId: "financial-news-date-january-17-2017",
          date: "January 17, 2017",
          filePath: "/2017/december-2016-earnings-release.pdf",
          text: "Washington Federal Announces Increase In Quarterly Earnings"
        },
        {
          id: "financial-news-link-january-4-2017",
          dateId: "financial-news-date-january-4-2017",
          date: "January 4, 2017",
          filePath: "/2017/wafd_01-2017_ceo-succession.pdf",
          text: "Washington Federal Announces CEO Succession Plan"
        }
      ],
      id: "financial-news-2017"
    },
    {
      year: 2016,
      newsData: [
        {
          id: "financial-news-link-december-20-2016",
          dateId: "financial-news-date-december-20-2016",
          date: "December 20, 2016",
          filePath: "/2016/wafd_12-2016_bob-peters.pdf",
          text: "Washington Federal Promotes Robert D. Peters"
        },
        {
          id: "financial-news-link-october-24-2016",
          dateId: "financial-news-date-october-24-2016",
          date: "October 24, 2016",
          filePath: "/2016/wafd_10-2016_dividend-release.pdf",
          text: "Washington Federal Announces Cash Dividend"
        },
        {
          id: "financial-news-link-october-19-2016",
          dateId: "financial-news-date-october-19-2016",
          date: "October 19, 2016",
          filePath: "/2016/september-2016-earnings-release.pdf",
          text: "Washington Federal Reports Record Earnings for Fiscal Year 2016"
        },
        {
          id: "financial-news-link-october-3-2016",
          dateId: "financial-news-date-october-3-2016",
          date: "October 3, 2016",
          filePath: "/2016/wafd_10-2016_cathy-cooper.pdf",
          text: "Washington Federal Promotes Cathy Cooper to Lead Retail Banking"
        },
        {
          id: "financial-news-link-september-21-2016",
          dateId: "financial-news-date-september-21-2016",
          date: "September 21, 2016",
          filePath: "/2016/wafd_9-2016_brad-goode.pdf",
          text: "Broadcaster Brad Goode to Join Washington Federal as Chief of Communications, Marketing and Community Relations"
        },
        {
          id: "financial-news-link-september-20-2016",
          dateId: "financial-news-date-september-20-2016",
          date: "September 20, 2016",
          filePath: "/2016/wafd_9-2016_additional-share-repurchase2.pdf",
          text: "Washington Federal Authorizes an Additional 5 Million Shares for Repurchase"
        },
        {
          id: "financial-news-link-september-14-2016",
          dateId: "financial-news-date-september-14-2016",
          date: "September 14, 2016",
          filePath: "/2016/wafd_9-2016_erin-lantz.pdf",
          text: "Washington Federal Announces Appointment of Erin Lantz to its Board of Directors "
        },
        {
          id: "financial-news-link-july-25-2016",
          dateId: "financial-news-date-july-25-2016",
          date: "July 25, 2016",
          filePath: "/2016/wafd_6-2016_dividend2.pdf",
          text: "Washington Federal Announces Cash Dividend of 14 Cents Per Share"
        },
        {
          id: "financial-news-link-july-14-2016",
          dateId: "financial-news-date-july-14-2016",
          date: "July 14, 2016",
          filePath: "/2016/wafd_6-2016_earnings.pdf",
          text: "Washington Federal Increases Earnings Per Share by 15%"
        },
        {
          id: "financial-news-link-may-23-2016",
          dateId: "financial-news-date-may-23-2016",
          date: "May 23, 2016",
          filePath: "/2016/wafd_5-2016_vincent-beatty.pdf",
          text: "Washington Federal Announces Vincent L. Beatty as Chief Financial Officer"
        },
        {
          id: "financial-news-link-april-19-2016",
          dateId: "financial-news-date-april-19-2016",
          date: "April 19, 2016",
          filePath: "/2016/wafd_3-2016-dividend.pdf",
          text: "Washington Federal Announces Cash Dividend of 14 Cents Per Share"
        },
        {
          id: "financial-news-link-april-14-2016",
          dateId: "financial-news-date-april-14-2016",
          date: "April 14, 2016",
          filePath: "/2016/wafd_3-2016_earnings.pdf",
          text: "Washington Federal Increases Earnings Per Share by 7.14%."
        },
        {
          id: "financial-news-link-january-20-2016",
          dateId: "financial-news-date-january-20-2016",
          date: "January 20, 2016",
          filePath: "/2016/wafd_12-2015_dividend.pdf",
          text: "Washington Federal Announces 7.7% Increase in Cash Dividend"
        },
        {
          id: "financial-news-link-january-19-2016",
          dateId: "financial-news-date-january-19-2016",
          date: "January 19, 2016",
          filePath: "/2016/wafd_12-2015_earnings.pdf",
          text: "Washington Federal Announces 1st Quarter 2016 Earnings"
        }
      ],
      id: "financial-news-2016"
    },
    {
      year: 2015,
      newsData: [
        {
          id: "financial-news-link-october-26-2015",
          dateId: "financial-news-date-october-26-2015",
          date: "October 26, 2015",
          filePath: "/2015/wafd_9-2015_dividend.pdf",
          text: "Washington Federal Announces Cash Dividend of 13 Cents per Share"
        },
        {
          id: "financial-news-link-october-21-2015",
          dateId: "financial-news-date-october-21-2015",
          date: "October 21, 2015",
          filePath: "/2015/wafd_9-2015_earnings.pdf",
          text: "Washington Federal Concludes its Fiscal Year with Record Earnings"
        },
        {
          id: "financial-news-link-july-27-2015",
          dateId: "financial-news-date-july-27-2015",
          date: "July 27, 2015",
          filePath: "/2015/wafd_6-2015_dividend.pdf",
          text: "Washington Federal Announces Cash Dividend of 13 Cents per Share"
        },
        {
          id: "financial-news-link-july-15-2015",
          dateId: "financial-news-date-july-15-2015",
          date: "July 15, 2015",
          filePath: "/2015/wafd_6-2015_release.pdf",
          text: "Washington Federal Announces Quarterly Earnings per Share Increase of 10%"
        },
        {
          id: "financial-news-link-may-11-2015",
          dateId: "financial-news-date-may-11-2015",
          date: "May 11, 2015",
          filePath: "/2015/wafd-mar-2015_overview_dadavidson_formatted.pdf",
          text: "D.A. Davidson and CO. Financial Institutions Conference Presentation"
        },
        {
          id: "financial-news-link-may-4-2015",
          dateId: "financial-news-date-may-4-2015",
          date: "May 4, 2015",
          filePath: "/2015/wafd_3-2015_dividend.pdf",
          text: "Washington Federal Announces Cash Dividend & Authorizes an Additional 5 Million Shares for Repurchase"
        },
        {
          id: "financial-news-link-april-15-2015",
          dateId: "financial-news-date-april-15-2015",
          date: "April 15, 2015",
          filePath: "/2015/wafd_3-2015_earnings.pdf",
          text: "Washington Federal Announces a 10.5% Increase in Quarterly Earnings Per Share"
        },
        {
          id: "financial-news-link-january-21-2015",
          dateId: "financial-news-date-january-21-2015",
          date: "January 21, 2015",
          filePath: "/2015/wafd_12-2014_cash-dividend.pdf",
          text: "Washington Federal Announces 18% Increase in Cash Dividend"
        },
        {
          id: "financial-news-link-january-14-2015",
          dateId: "financial-news-date-january-14-2015",
          date: "January 14, 2015",
          filePath: "/2015/wafd_12-2014_earnings.pdf",
          text: "Washington Federal Announces Quarterly Earnings of $0.39 Cents Per Diluted Share"
        }
      ],
      id: "financial-news-2015"
    },
    {
      year: 2014,
      newsData: [
        {
          id: "financial-news-link-october-22-2014",
          dateId: "financial-news-date-october-22-2014",
          date: "October 22, 2014",
          filePath: "/2014/wafd_9-2014_earnings.pdf",
          text: "Washington Federal Completes Fiscal Year with Record Earnings"
        },
        {
          id: "financial-news-link-september-23-2014",
          dateId: "financial-news-date-september-23-2014",
          date: "September 23, 2014",
          filePath: "/2014/or-community-giving-press-release.pdf",
          text: "Washington Federal Exceeds $250 Million Pledge by $108 Million for Community Development Funding in Oregon"
        },
        {
          id: "financial-news-link-september-22-2014",
          dateId: "financial-news-date-september-22-2014",
          date: "September 22, 2014",
          filePath: "/2014/wafd_9-2014_dividend.pdf",
          text: "Washington Federal Announces 127th Consecutive Quarterly Cash Dividend"
        },
        {
          id: "financial-news-link-july-15-2014",
          dateId: "financial-news-date-july-15-2014",
          date: "July 15, 2014",
          filePath: "/2014/wafd_6-2014_earningsrelease.pdf",
          text: "Washington Federal Announces Quarterly Earnings per Share Increase of 4%"
        },
        {
          id: "financial-news-link-june-23-2014",
          dateId: "financial-news-date-june-23-2014",
          date: "June 23, 2014",
          filePath: "/2014/wafd_6-2014_dividend.pdf",
          text: "Washington Federal Announces 10% Increase in Cash Dividend"
        },
        {
          id: "financial-news-link-june-9-2014",
          dateId: "financial-news-date-june-9-2014",
          date: "June 9, 2014",
          filePath: "/2014/wafed-dadco-may-2014-conference-final.pdf",
          text: "June 9, 2014 D.A. Davidson and CO. 16th Annual Financial Services Conference May 14th, 2014"
        },
        {
          id: "financial-news-link-april-15-2014",
          dateId: "financial-news-date-april-15-2014",
          date: "April 15, 2014",
          filePath: "/2014/wafd_3-2014_earnings-release.pdf",
          text: "Washington Federal Announces Quarterly Earnings per Share Increase of 12%"
        },
        {
          date: "March 24, 2014",
          id: "financial-news-link-march-24-2014-1",
          dateId: "financial-news-date-march-24-2014-1",
          filePath: "/2014/wafd_managementrealignment_3-2014.pdf",
          text: "Washington Federal Announces Brent Beardall Promoted to Chief Banking Officer and Diane Kelleher to Chief Financial Officer"
        },
        {
          date: "March 24, 2014",
          id: "financial-news-link-march-24-2014-2",
          dateId: "financial-news-date-march-24-2014-2",
          filePath: "/2014/wafd_3_2014_dividend.pdf",
          text: "Washington Federal Declares Cash Dividend"
        },
        {
          date: "January 23, 2014",
          id: "financial-news-link-january-23-2014-1",
          dateId: "financial-news-date-january-23-2014-1",
          filePath: "/2014/press-release-1-23-14-final.pdf",
          text: "Washington Federal Announces the Acquisition of 23 Branches in Arizona and Nevada"
        },
        {
          date: "January 23, 2014",
          id: "financial-news-link-january-23-2014-2",
          dateId: "financial-news-date-january-23-2014-2",
          filePath: "/2014/sw-fact-sheet-final.pdf",
          text: "Deposit Acquisition Fact Sheet As of January 23, 2014"
        },
        {
          id: "financial-news-link-january-13-2014",
          dateId: "financial-news-date-january-13-2014",
          date: "January 13, 2014",
          filePath: "/2014/wafd_12-2013_earningsrelease.pdf",
          text: "Washington Federal Announces Increase in Quarterly Earnings Per Share of 18% and Completes Acquisition of 51 Branches"
        }
      ],
      id: "financial-news-2014"
    },
    {
      year: 2013,
      newsData: [
        {
          id: "financial-news-link-december-23-2013",
          dateId: "financial-news-date-december-23-2013",
          date: "December 23, 2013",
          filePath: "/2013/dec-2013-cash-dividend-press-release.pdf",
          text: "Washington Federal Declares Cash Dividend"
        },
        {
          id: "financial-news-link-october-14-2013",
          dateId: "financial-news-date-october-14-2013",
          date: "October 14, 2013",
          filePath: "/2013/wafd_9-2013_earnings.pdf",
          text: "Washington Federal Completes Fiscal Year with Record Earnings"
        },
        {
          id: "financial-news-link-september-23-2013",
          dateId: "financial-news-date-september-23-2013",
          date: "September 23, 2013",
          filePath: "/2013/wafd_9-2013_dividend.pdf",
          text: "Washington Federal Increases Cash Dividend and Authorizes an Additional 10 Million Shares for Repurchase"
        },
        {
          date: "July 18, 2013",
          id: "financial-news-link-july-18-2013-1",
          dateId: "financial-news-date-july-18-2013-1",
          filePath: "/2013/wafd_6-2013_earnings.pdf",
          text: "Washington Federal Reports Higher Net Income, Announces the Acquisition of 51 Branches in Four States, and Completes Conversion to a National Bank Charter"
        },
        {
          date: "July 18, 2013",
          id: "financial-news-link-july-18-2013-2",
          dateId: "financial-news-date-july-18-2013-2",
          filePath: "/2013/wafd_deposit_acquisition_summary.pdf",
          text: "Deposit Acquisition Summary"
        },
        {
          id: "financial-news-link-june-24-2013",
          dateId: "financial-news-date-june-24-2013",
          date: "June 24, 2013",
          filePath: "/2013/wafd_6-2013_dividend-charterconversion.pdf",
          text: "Washington Federal Declares Cash Dividend and Receives Regulatory Approval for Charter Conversion"
        },
        {
          id: "financial-news-link-may-9-2013",
          dateId: "financial-news-date-may-9-2013",
          date: "May 9, 2013",
          filePath: "/2013/d-a--davidson-conference.pdf",
          text: "D. A. Davidson Conference"
        },
        {
          id: "financial-news-link-april-16-2013",
          dateId: "financial-news-date-april-16-2013",
          date: "April 16, 2013",
          filePath: "/2013/wafd_3-2013_earnings.pdf",
          text: "Washington Federal Reports Quarterly Net Income Increased 5.6% to $36.0 Million"
        },
        {
          id: "financial-news-link-march-25-2013",
          dateId: "financial-news-date-march-25-2013",
          date: "March 25, 2013",
          filePath: "/2013/wafd_3-2013_dividend.pdf",
          text: "Washington Federal Increases Cash Dividend 12.5%"
        },
        {
          id: "financial-news-link-january-15-2013",
          dateId: "financial-news-date-january-15-2013",
          date: "January 15, 2013",
          filePath: "/2013/wafd_12-2012_earnings.pdf",
          text: "Washington Federal Reports Quarterly Net Income Increased 7% to 25% Million"
        },
        {
          id: "financial-news-link-january-10-2013",
          dateId: "financial-news-date-january-10-2013",
          date: "January 10, 2013",
          filePath: "/2013/community-development-pledge-1-9-2013.pdf",
          text: "Washington Federal Pledges $250 Million in Community Development Funding in the State of Oregon"
        }
      ],
      id: "financial-news-2013"
    }
  ];

  const [expandAll, setExpandAll] = useState(true);

  const initialState = Array(financialNewsData.length - 1).fill(false);
  initialState.unshift(true); // Keep the first accordion open
  const [collapseClass, setCollapseClass] = useState(initialState);

  // Handle individual accordion toggle
  function updateCollapseState(index) {
    const newState = [...collapseClass];
    newState[index] = !newState[index];
    setCollapseClass(newState);
  }

  // Handle toggling "Expand All" / "Collapse All"
  function toggleExpandAll() {
    const newState = expandAll
      ? Array(financialNewsData.length).fill(true)
      : Array(financialNewsData.length).fill(false);
    setCollapseClass(newState);
    setExpandAll(!expandAll);
  }

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <MktoForm />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <Navigation {...InvestorRelationsNavData} />
      <section className="container section-padding-adjusted">
        <div className="row align-items-center">
          <div className="col-lg">
            <h1>Financial News</h1>
          </div>
          <div className="col-lg-auto">
            <button
              id="subscribe-to-news-letter-cta"
              className="btn btn-link mb-3 mb-lg-0"
              onClick={() => showMktoForm(1428)}
            >
              Subscribe to Financial News Email
              <Icon name="envelope" class="ml-2" lib="far" />
            </button>
          </div>
        </div>
        <div className="text-right mb-3">
          <div
            class="text-decoration-none text-primary"
            role="button"
            aria-expanded={!expandAll}
            onClick={(ev) => {
              ev.preventDefault();
              toggleExpandAll();
            }}
            id="accordion-expand-collapse-all-btn"
          >
            <span className="h5">{expandAll ? "Expand" : "Collapse"} All</span>
            <Icon class="ml-2" lib="fas" name={expandAll ? "chevron-down" : "chevron-up"} />
          </div>
        </div>
        <div className="std-accordion mb-4">
          {financialNewsData.map((data, index) => {
            return (
              <div className={index === data.length || index === 0 ? "border-y" : "border-bottom"} key={index}>
                <button
                  id={`${data.year}-financial-news-toggle`}
                  onClick={(e) => {
                    updateCollapseState(index);
                  }}
                  className="border-0 w-100 d-flex accordion-toggle py-1 px-4 justify-content-between align-items-center"
                  aria-controls={`accordion-${index}`}
                  aria-expanded={collapseClass[index]}
                  aria-labelledby={`accordion-${index}`}
                >
                  <h5 className="mb-1" id={`${data.year}-financial-news-accordion-header`}>
                    {data.year}
                  </h5>
                  <Icon class="fa-2x text-success mx-2 my-1" name={`${collapseClass[index] ? "minus" : "plus"}`} />
                </button>
                <Collapse in={collapseClass[index]} id={`${data.year}-financial-news-toggle-show`}>
                  <ul className="py-3 ml-4 list-unstyled" key={index}>
                    {data.newsData.length > 0 &&
                      data.newsData.map((news, index) => {
                        return (
                          <li key={index}>
                            <h5 className="mb-2 font-weight-normal" id={news.dateId}>
                              {news.date}
                            </h5>
                            {news.filePath || news.url ? (
                              <p>
                                <a
                                  id={news.id}
                                  className="text-decoration-none d-flex"
                                  href={news.url ? news.url : `/documents/financial-news${news.filePath}`}
                                  target={news.url && !news.url.includes(".com") ? "_self" : "_blank"}
                                  rel="noreferrer"
                                >
                                  {news.url ? (
                                    news.externalUrl ? (
                                      <Icon class="mr-2 mt-1" name="external-link-alt" />
                                    ) : (
                                      <Icon class="mr-2 mt-1" name="arrow-right" />
                                    )
                                  ) : (
                                    <Icon class="mr-2 mt-1" name="file-alt" />
                                  )}
                                  {news.text}
                                </a>
                              </p>
                            ) : (
                              news.element
                            )}
                          </li>
                        );
                      })}
                  </ul>
                </Collapse>
              </div>
            );
          })}
        </div>
        <NotificationAlert
          type="primary"
          id="investor-info-notification-alert"
          bodyText='For more information about WaFd, Inc., our publicly traded holding company (Nasdaq: WAFD), or to request
      an investor kit, please email us at <a href="mailto:info@wafd.com" id="notification-alert-email-link">info@wafd.com</a> or call <a href="tel:206-626-8178" id="notification-alert-tel-link">206-626-8178</a>'
        />
      </section>

      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default FinancialNews;
